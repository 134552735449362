<template>
  <g>
    <svg:style>
      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
      stroke: #aeaeae;
      stroke-miterlimit: 10;
      stroke-width: 0.25px;
      }

      .cls-1 {
      fill: url(#BG-gradient);
      }

      .cls-2 {
      fill: none;
      }

      .cls-3 {
      fill: url(#handle-gradient);
      }

      .cls-4 {
      fill: url(#inox-gradient);
      }

      .cls-5 {
      fill: url(#glass-pattern);
      }

      .cls-6 {
      fill: url(#inox-gradient-2);
      }

      .cls-7 {
      fill: url(#glass-pattern);
      }

      .cls-8, .cls-9 {
      stroke-linecap: square;
      }

      .cls-8 {
      fill: url(#inox-gradient-3);
      }

      .cls-9 {
      fill: url(#inox-gradient-4);
      }

      .cls-34{fill:url(#New_Gradient_Swatch_3);}
      .cls-35{fill:url(#New_Gradient_Swatch_3-2);}
      .cls-36{fill:url(#New_Gradient_Swatch_3-3);}
      .cls-37{fill:url(#New_Gradient_Swatch_3-4);}
      .cls-38{fill:url(#New_Gradient_Swatch_3-5);}
      .cls-39{fill:url(#New_Gradient_Swatch_3-6);}
      .cls-40{fill:url(#New_Gradient_Swatch_3-7);}
      .cls-41{fill:url(#New_Gradient_Swatch_3-8);}
      .cls-42{fill:url(#New_Gradient_Swatch_3-9);}
      .cls-43{fill:url(#New_Gradient_Swatch_3-10);}
      .cls-44{fill:url(#New_Gradient_Swatch_3-11);}
      .cls-45{fill:url(#New_Gradient_Swatch_3-12);}
      .cls-46{fill:url(#New_Gradient_Swatch_3-13);}
      .cls-47{fill:url(#New_Gradient_Swatch_3-14);}
      .cls-48{fill:url(#New_Gradient_Swatch_3-15);}
      .cls-49{fill:url(#New_Gradient_Swatch_3-16);}

      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>

    <linearGradient id="handle-gradient" :x1="doorLeftWidth1 + 11.16" :y1="doorTopHeight1 + 148.47"
      :x2="doorLeftWidth1 + 16.75" :y2="doorTopHeight1 + 148.47" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc" />
      <stop offset="0.29" stop-color="#989a9e" />
      <stop offset="0.65" stop-color="#bdbdc1" />
      <stop offset="0.87" stop-color="#9d9fa2" />
      <stop offset="1" stop-color="#a6a8aa" />
      <stop offset="1" stop-color="#c2c5c9" />
    </linearGradient>
    <linearGradient id="inox-gradient" :x1="doorLeftWidth1 + 35.02" :y1="doorTopHeight1 + 106.35"
      :x2="doorLeftWidth1 + 63.5" :y2="doorTopHeight1 + 106.35" xlink:href="#handle-gradient" />
    <linearGradient id="glass-gradient" :x1="doorLeftWidth1 + 49.17" :y1="doorTopHeight1 + 157.08"
      :x2="doorLeftWidth1 + 49.17" :y2="doorTopHeight1 + 55.68" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4" />
      <stop offset="0.5" stop-color="#dee8e8" />
      <stop offset="1" stop-color="#d1cece" />
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox-gradient" :x1="doorLeftWidth1 + 77.16"
      :y1="doorTopHeight1 + 106.5" :x2="doorLeftWidth1 + 104.8" :y2="doorTopHeight1 + 106.5"
      xlink:href="#handle-gradient" />
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" :x1="doorLeftWidth1 + 90.93"
      :y1="doorTopHeight1 + 157.08" :x2="doorLeftWidth1 + 90.93" :y2="doorTopHeight1 + 55.68"
      xlink:href="#glass-gradient" />
    <linearGradient id="inox-gradient-3" data-name="inox-gradient" :x1="doorLeftWidth1 + 860.27"
      :y1="doorTopHeight1 + 7496.18" :x2="doorLeftWidth1 + 860.27" :y2="doorTopHeight1 + 7468.15"
      xlink:href="#handle-gradient" />
    <linearGradient id="inox-gradient-4" data-name="inox-gradient" :x1="doorLeftWidth1 + 860.27"
      :y1="doorTopHeight1 + 7454.28" :x2="doorLeftWidth1 + 860.27" :y2="doorTopHeight1 + 7426.24"
      xlink:href="#handle-gradient" />

    <!-- Molding 1 gradient BEGIN -->
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <!-- Molding 1 gradient END -->
    <g id="С6">
      <!--...-->
      <polyline id="inox-2" v-if="showInox" data-name="inox" class="cls-6" :points="`
                    ${doorLeftWidth1 + 77.16} ${doorTopHeight1 + 51.3}
                                ${doorLeftWidth1 + 77.16} ${doorTopHeight1 + 161.7}
                                ${doorLeftWidth1 + 104.8} ${doorTopHeight1 + 161.7}
                                ${doorLeftWidth1 + 104.8} ${doorTopHeight1 + 51.3}
                                ${doorLeftWidth1 + 77.16} ${doorTopHeight1 + 51.3}
                                `" />
      <!--...-->
      <polyline id="glass-2" data-name="glass" class="cls-7" :points="`
                                ${doorLeftWidth1 + 81.5} ${doorTopHeight1 + 55.68}
                                ${doorLeftWidth1 + 81.5} ${doorTopHeight1 + 157.08}
                                ${doorLeftWidth1 + 100.22} ${doorTopHeight1 + 157.08}
                                ${doorLeftWidth1 + 100.22} ${doorTopHeight1 + 55.68}
                                ${doorLeftWidth1 + 81.5} ${doorTopHeight1 + 55.68}
                                `" />
      <!--...-->
      <polyline id="inox" v-if="showInox" class="cls-4" :points="`
                                ${doorLeftWidth1 + 35.02} ${doorTopHeight1 + 51.3}
                                ${doorLeftWidth1 + 35.02} ${doorTopHeight1 + 161.41}
                                ${doorLeftWidth1 + 63.5} ${doorTopHeight1 + 161.41}
                                ${doorLeftWidth1 + 63.5} ${doorTopHeight1 + 51.3}
                                ${doorLeftWidth1 + 35.02} ${doorTopHeight1 + 51.3}
                                `" />
      <!--...-->
      <polyline id="glass" class="cls-5" :points="`
                                ${doorLeftWidth1 + 39.84} ${doorTopHeight1 + 55.68}
                                ${doorLeftWidth1 + 39.84} ${doorTopHeight1 + 157.08}
                                ${doorLeftWidth1 + 58.5} ${doorTopHeight1 + 157.08}
                                ${doorLeftWidth1 + 58.5} ${doorTopHeight1 + 55.68}
                                ${doorLeftWidth1 + 39.84} ${doorTopHeight1 + 55.68}
                                `" />
      <!--...-->
      <path id="inox-3" v-if="showInox" data-name="inox" class="cls-8" :d="`
                M${doorLeftWidth1 + 825.38},${doorTopHeight1 + 7468.15}
                v28
                h69.78
                v-28
                H${doorLeftWidth1 + 825.38}
                Z
                m65,23.07
                H${doorLeftWidth1 + 830.2}
                V${doorTopHeight1 + 7473}
                h60.14
                v18.25
                Z
                `" transform="translate(-790.36 -7249.7)" />
      <path id="inox-4" v-if="showInox" data-name="inox" class="cls-9" :d="`
                M${doorLeftWidth1 + 825.38},${doorTopHeight1 + 7426.24}
                v28
                h69.78
                v-28
                H${doorLeftWidth1 + 825.38}
                Z
                m65,23.07
                H${doorLeftWidth1 + 830.2}
                v-18.25
                h60.14
                v18.25
                Z
                `" transform="translate(-790.36 -7249.7)" />
      <!--...-->
      <!-- Molding BEGIN -->
      <g id="Moldings" v-if="showMolding">
        <g id="Molding-1L">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 57.29} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 57.29} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 38.81} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 57.47} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 38.81} ${doorTopHeight1 + 156.49}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 38.81} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 57.47} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 57.29} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 57.47} ${doorTopHeight1 + 156.49}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 57.29} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 57.29} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 38.81} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 57.47} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 38.81} ${doorTopHeight1 + 156.49}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 38.81} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 57.47} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 57.29} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 62.29} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 57.47} ${doorTopHeight1 + 156.49}
            `"/>
        </g>
        <g id="Molding-1R" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 80.57} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 80.74} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 80.74} ${doorTopHeight1 + 156.49}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 80.57} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 80.74} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 80.57} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 156.49}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 80.57} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 80.74} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 80.74} ${doorTopHeight1 + 156.49}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 80.57} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 80.74} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 75.74} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 80.57} ${doorTopHeight1 + 55.09}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 156.49}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 55.09}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 50.71}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 160.88}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 156.49}
            `"/>
        </g>
        <g id="Molding-2" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 180.34}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 199.61}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 180.34}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 199.61}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 180.34}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 199.61}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 180.34}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 199.61}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 180.34}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 175.96}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 204}
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 199.61}
            `"/>
        </g>
        <g id="Molding-3" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 222.18}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 241.45}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 222.18}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 241.45}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 99.28} ${doorTopHeight1 + 222.18}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 99.4} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 241.45}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 33.81} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 38.64} ${doorTopHeight1 + 222.18}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 241.45}
              ${doorLeftWidth1 + 99.22} ${doorTopHeight1 + 222.18}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 217.79}
              ${doorLeftWidth1 + 104.22} ${doorTopHeight1 + 245.83}
              ${doorLeftWidth1 + 99.34} ${doorTopHeight1 + 241.45}
            `"/>
        </g>
      </g>
      <!-- Molding END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth / 2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight / 2 - 148
    },
    doorLeftWidth1m() {
      return this.doorLeftWidth + this.doorWidth / 2 - 70 + 1.1
    },
    doorTopHeight1m() {
      return this.doorTopHeight + this.doorHeight / 2 - 148 + 0.5
    },
  }
}
</script>
